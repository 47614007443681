<template>
  <v-card>
    <v-toolbar class="page-primary-card-header">
      <v-list-item dark>
        <v-list-item-avatar color="primary lighten-2">
          <v-icon>{{card_header_props.header.icon}}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="headline">{{card_header_props.header.headLine}}</v-list-item-title>
          <v-list-item-subtitle>{{card_header_props.header.subTitle}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-btn
        @click="card_header_props.helper.page_helper = !card_header_props.helper.page_helper"
        icon
        dark
      >
        <v-icon>info</v-icon>
      </v-btn>
    </v-toolbar>

    <v-dialog v-model="card_header_props.helper.page_helper" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>{{$t("_common.How_can_I_use_this_form")}}</v-card-title>

        <v-card-text>{{$t("_add_questions._info.content")}}</v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="card_header_props.helper.page_helper = false">{{$t("_common.OK")}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="p-5">
      <v-container class="m-0 p-0 col-12">

        <v-row>
          <v-col cols="12" sm="12" md="4">
            <v-card xs12 width="100%">
              <v-sheet class="pa-4">
                <v-text-field
                  v-model="search"
                  :label="this.$t('_add_questions.Search_Topic')"
                  flat
                  hide-details
                  clearable
                  clear-icon="mdi-close-circle-outline"
                ></v-text-field>
              </v-sheet>
              <v-card style="overflow:auto;" height="775px" flat>
                <v-treeview
                  :items="treeTopicData"
                  :search="search"
                  v-model="questionForm.Topics"
                  item-children="Children"
                  item-key="Id"
                  item-text="Title"
                  selectable
                  hoverable
                  open-all
                  multiple
                  color="teal"
                  dense
                  selection-type="independent"
                  @input="onSelectedTopic"
                  style="min-width: fit-content;"
                  :rules="validations.selectTopicRules"
                />
              </v-card>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="8">

            <v-card class="p-4" flat width="100%">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card class="mb-6">
                  <ckeditor
                    :editor="editor"
                    :config="editorConfig"
                    v-model="questionForm.Text"
                    :rules="validations.questionTextRules"
                  ></ckeditor>
                   <v-file-input
                        prepend-icon="mdi-camera"
                        v-model="questionForm.ImageUrl"
                        accept= "image/*"
                        truncate-length="50"
                        small-chips
                        outlined
                        dense
                      />

                </v-card>

                <v-select
                v-model="questionForm.QuestionType"
                :items="$goc.ENUMS.QuestionStrings"
                 item-text="text"
                 item-value="val"></v-select>

                <v-col v-if="questionForm.QuestionType != 2 && questionForm.QuestionType != 3">{{$t("_add_questions.Enter_one_ore_more")}}</v-col>


                <v-container style="max-height: 355px; overflow: auto;" v-if="questionForm.QuestionType == 1 || questionForm.QuestionType == 4">

                  <v-col
                    class="grey lighten-5 p-0 pl-5 pr-4"
                    id="choiceList"
                    v-for="(input, index) in inputs"
                    :key="input.id || index"
                    v-on:keyup.insert="addChoiceInput()"
                    v-on:keyup.esc="deleteChoice(input)"
                    :rules="validations.choicesRules"
                  >
                    <v-row align="center" v-if="questionForm.QuestionType == 1">
                      <div class="d-flex justify-space-between select-lms-area">
                                              <v-radio-group v-model="input.Correct">
                        <v-radio :value="true"  @click="setAsCorrect(index)">
                        </v-radio>
                      </v-radio-group>
                      <v-textarea
                        class="ma-2 choose-question"
                        v-model="input.Text"
                        :id="createChoiceId(input.id || index)"
                        clearable
                        :label="createChoiceLabel(input.id || index)"
                        type="text"
                        rows="1"
                        auto-grow

                      >
                      </v-textarea>
                       <v-file-input
                        class= "ma-5 file-selector"
                        prepend-icon="mdi-camera"
                        v-model="input.ImageUrl"
                        accept= "image/*"
                        truncate-length="20"
                        small-chip
                        outlined
                        dense
                      />
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click="deleteChoice(input)" small color="error" dark fab>
                            <v-icon>mdi-minus</v-icon>
                          </v-btn>
                        </template>
                        <span>{{$t("_common.Esc")}}</span>
                      </v-tooltip>
                      </div>
                       <template v-slot:prepend></template>

                    </v-row>

                    <v-row align="center"   v-if="questionForm.QuestionType == 4">
                      <v-textarea
                        class="ma-2"
                        v-model="input.Text"
                        :id="createKeyId(input.id || index)"
                        clearable
                        :label="createKeyLabel(input.id || index)"
                        type="text"
                        rows="1"
                        auto-grow
                        outlined
                        dense
                        v-on:input="checkValidForMatchingChoice(input)"
                      ></v-textarea>
                      <v-file-input
                      class="mr-5"
                        prepend-icon="mdi-camera"
                        v-model="input.ImageKeyUrl"
                        accept= "image/*"
                        truncate-length="15"
                        small-chips
                        outlined
                        dense

                      />

                      <template v-slot:prepend></template>

                      <v-textarea
                        class="ma-2"
                        v-model="input.Value"
                        :id="createValeuId(input.id || index)"
                        clearable
                        :label="createValueLabel(input.id || index)"
                        type="text"
                        rows="1"
                        auto-grow
                        outlined
                        dense
                        v-on:input="checkValidForMatchingChoice(input)"
                      ></v-textarea>
                      <v-file-input
                        prepend-icon="mdi-camera"
                        v-model="input.ImageValueUrl"
                        accept= "image/*"
                        truncate-length="15"
                        small-chips
                        outlined
                        dense
                      />

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn  v-on="on" @click="deleteChoice(input)" small color="error" dark fab>
                            <v-icon>mdi-minus</v-icon>
                          </v-btn>
                        </template>
                        <span>Esc</span>
                      </v-tooltip>
                    </v-row>
                  </v-col> <!-- end -->

                </v-container>
                <v-container style="max-height: 355px; overflow: auto;" v-if="questionForm.QuestionType == 2">
                  <v-col
                    class="grey lighten-5 p-0 pl-5 pr-4"
                    id="choiceList-2"
                  >
                    <v-row align="center">
                      <v-radio-group v-model="amITreuOrFalse">
                        <v-radio :label= "this.$t('_add_questions.True')" :value="true">

                        </v-radio>
                        <v-radio :label= "this.$t('_add_questions.False')" :value="false">

                        </v-radio>
                      </v-radio-group>
                    </v-row>
                  </v-col>
                </v-container>
                <v-card-text style="position: relative" v-if="questionForm.QuestionType != 2 && questionForm.QuestionType != 3">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        absolute
                        dark
                        fab
                        top
                        small
                        right
                        color="success"
                        @click="addChoiceInput()"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>{{$t("_common.Enter")}}</span>
                  </v-tooltip>
                </v-card-text>


                <v-slider
                  v-model="questionForm.Point"
                  class="align-left"
                  :label='this.$t("_add_questions.Question_Score")'
                  :max="maxPoint"
                  :min="minpoint"
                  hide-details
                  :thumb-size="16"
                  thumb-label="always"
                  :rules="validations.questionScoreRules"
                ></v-slider>
              </v-form>
            </v-card>
          </v-col>
			  </v-row>

      </v-container>

      <div slot="footer">
        <vs-row vs-justify="flex-end">
          <v-btn @click="resetQuestionForm" class="mr-2" dark color="warning">{{$t("_common.Reset")}}</v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn @click="addQuestion" v-on="on" dark color="success">{{$t("_common.Add")}}</v-btn>
            </template>
            <span>{{$t("_add_questions.Add_a_Questions")}}</span>
          </v-tooltip>
        </vs-row>
      </div>
    </div>
  </v-card>
</template>

<script>
import { VSelectTree, VTree } from "vue-tree-halower";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import Sortable from "sortablejs";

require("../../assets/css/neo.css");

export default {
  data() {
    return {
      search: null,
      valid: true,
      editorConfig: {
        placeholder: this.$t("_add_questions.Enter_questions"),
        language: this.$t("_common.LanguageOption"),
        toolbar: ["heading","|","bold","italic","link","bulletedList","numberedList","|","blockQuote","insertTable","undo","redo"],
      },
      validations: {
        questionTextRules: this.$goc.validate
          .create()
          .required()
          .min(15)
          .max(300),
        choicesRules: this.$goc.validate
          .create()
          .min(2)
          .max(10),

        questionScoreRules: this.$goc.validate.create().checkVal(1, 100),
        questionImageRules: this.$goc.validate.create().file(),
        selectTopicRules: this.$goc.validate
          .create()
          .required()
          .min(1)
      },
      questionForm: {
        Topics: [],
        Choices: [],
        MatchingChoices: [],
        Text: "",
        Point: 0,
        QuestionType: 1,
        ImageUrl: null,
      },
      isHidden: true,
      activeTopics: [],
      minpoint: 0,
      maxPoint: 100,
      searchword: "",
      topicName: "",
      topicParent: "",
      treeTopicData: [],
      treeTopicDataModel: [],
      questionTreeData: [],
      questionImageModel: null,

      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.2
      },
      inputs: [
        {
          label: this.$t("_add_questions.Choice") + " #1",
          Text: "",
          ImageKeyUrl: "",
          Value: "",
          ImageValueUrl: "",
          Correct: true,
        }
      ],
      editor: ClassicEditor,
      card_header_props: {
        header: {
          headLine: this.$t('_add_questions.Add_Questions'),
          subTitle: this.$t('_add_questions.Subtitle'),
          icon: "menu_book"
        },
        helper: {
          absolute: true,
          opacity: 0.8,
          overlay: false,
          page_helper: false
        }
      },
      choose: [],
      trueFalse: [],
      matchingChoices: [],
      clasic: [],
      questionTypes: [],
      oldQuestionType: 1,
      amITreuOrFalse:true,
    };
  },
  props: {
    topic: Number,
    default: 0,
  },
  watch: {
    "questionForm.QuestionType"(val) {
        if(this.oldQuestionType === this.$goc.ENUMS.QUESTION_TYPE.MultipleChoice){
          this.choose = this.inputs;
        }else if(this.oldQuestionType === this.$goc.ENUMS.QUESTION_TYPE.TrueFalse){
          this.trueFalse = this.inputs;
        }else if(this.oldQuestionType === this.$goc.ENUMS.QUESTION_TYPE.Matching){
          this.matchingChoices = this.inputs;
        }else if(this.oldQuestionType === this.$goc.ENUMS.QUESTION_TYPE.OpenEnded){
           this.clasic = this.inputs;
        }
        this.inputs = [];

        if(val === this.$goc.ENUMS.QUESTION_TYPE.MultipleChoice){
          this.inputs = this.choose;
          this.oldQuestionType = this.$goc.ENUMS.QUESTION_TYPE.MultipleChoice;
        }else if(val === this.$goc.ENUMS.QUESTION_TYPE.TrueFalse){
          if(this.trueFalse.length !== 0)
            this.inputs = this.trueFalse;
          else{
            this.inputs.push({label: "True",Text: "True",Value: "True",Correct: true});
            this.inputs.push({label: "False",Text: "False",Value: "False",Correct: false});
          }
          this.oldQuestionType = this.$goc.ENUMS.QUESTION_TYPE.TrueFalse;
        }else if(val === this.$goc.ENUMS.QUESTION_TYPE.Matching){
          if(this.matchingChoices.length !== 0)
            this.inputs = this.matchingChoices;
          else
            this.inputs[0] = {label: "Choice #1",Text: "",Value: "",Correct: true};
          this.oldQuestionType = this.$goc.ENUMS.QUESTION_TYPE.Matching;
        }else if(val === this.$goc.ENUMS.QUESTION_TYPE.OpenEnded){
          this.inputs = this.clasic;
          this.oldQuestionType = this.$goc.ENUMS.QUESTION_TYPE.OpenEnded;
        }
    },
    amITreuOrFalse(val) {
      if(this.questionForm.QuestionType === this.$goc.ENUMS.QUESTION_TYPE.TrueFalse){
        if(val === true){
          this.inputs[0].Correct = true;
          this.inputs[1].Correct = false;
        }else{
          this.inputs[0].Correct = false;
          this.inputs[1].Correct = true;
        }
      }

    },

  },
  methods: {
    setAsCorrect(index) {
      this.inputs = this.inputs.map((i, inputIndex) => {
        i.Correct = index === inputIndex
        return i;
      });
    },
    init() {

      this.$goc.console.log(this.topic);
    },
    resetQuestionForm() {
      this.inputs = [
        {
          label: this.$t("_add_questions.Choice") + " #1",
          Text: "",
          Value: "",
          Correct: true,
          ImageValueUrl: "",
          ImageKeyUrl: "",
          ImageUrl: "",
        }
      ];
      this.questionForm.Text = "";
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    validate() {
      return (
        !!this.$refs.form.validate() &&
        this.questionTextValid() &&
        this.topicsValid() &&
        this.choicesValid()
      );
    },
    checkValidForMatchingChoice(input) {
      console.log(input);
      if(input['Text'] !== "" && input['Value'] !== "")
        input['Correct'] = true;
      else if(input['Text'] === "" && input['Value'] === "")
        input['Correct'] = false;
      else
        input['Correct'] = false;
    },
    questionTextValid() {
      if (this.questionForm.Text.length > 15) return true;
      else {
        this.$goc.notify.error({
          message: this.$t('_add_questions.PleaseEnter15Characters')
        });
        return false;
      }
    },
    topicsValid() {
      if (this.questionForm.Topics.length > 0) return true;
      else {
        this.$goc.notify.error({ message: this.$t('_add_questions.PleaseSelectTopic')});
        return false;
      }
    },
    choicesValid() {
      if (this.inputs.length > 1 || this.questionForm.QuestionType  == this.$goc.ENUMS.QUESTION_TYPE.OpenEnded) return true;
      else {
        this.$goc.notify.warning({
          title: this.$t('_add_questions.Choice'),
            message: this.$t('_add_questions.You_have_to_add_more_than_one_choice')
        });
        return false;
      }
    },
    // fix this later
    createChoiceId(index) {
      return "choice" + (index + 1);
    },
    createKeyId(index) {
      return "key" + (index + 1);
    },
    createValeuId(index) {
      return "value" + (index + 1);
    },
    createChoiceLabel(index) {
      return this.$t('_add_questions.Choice') + " #" + (index + 1);
    },
    createKeyLabel(index) {
      return "Key" + (index + 1);
    },
    createValueLabel(index) {
      return "Value" + (index + 1);
    },
    getTopics() {
      this.$goc.request.get("/api/Lexicon/Topic", body => {
        this.treeTopicData = body.Result.Topics;
      });
    },
    async addQuestion() {
      console.log(this.inputs)
      if (this.validate()) {
        let check = false;
        let count = 0;
        this.inputs.forEach(value => {
          if (value.Correct) {
            check = true;
            count += 1;
          }
        });
        if(this.questionForm.ImageUrl != null)
        {
              this.questionForm.ImageUrl = await this.imageUpload(this.questionForm.ImageUrl);
        }
        if (count == 1 && check || this.questionForm.QuestionType  == this.$goc.ENUMS.QUESTION_TYPE.OpenEnded || ( check && this.questionForm.QuestionType == this.$goc.ENUMS.QUESTION_TYPE.Matching)) {
          if (this.questionForm.QuestionType == this.$goc.ENUMS.QUESTION_TYPE.MultipleChoice){
              this.questionForm.Choices = this.inputs;
              for (let i = 0; i < this.inputs.length; i += 1) {
                if(!this.inputs[i].ImageUrl && !this.inputs[i].Text){
                  this.$goc.notify.error({
                  message: this.$t('_add_questions.Please_enter_a_question_text_or_an_image')
                  });
                  return;
                }else{
                  if(this.inputs[i].ImageUrl != null) {
                    this.inputs[i].ImageUrl = await this.imageUpload(this.inputs[i].ImageUrl);
                  }
                }
              }
          }
          if(this.questionForm.QuestionType == this.$goc.ENUMS.QUESTION_TYPE.TrueFalse)
            this.questionForm.Choices = this.inputs;

          if (this.questionForm.QuestionType == this.$goc.ENUMS.QUESTION_TYPE.Matching) {

            for (let i = 0; i < this.inputs.length; i += 1) {
              this.inputs[i].Key = this.inputs[i].Text;

              if(this.inputs[i].ImageKeyUrl != null) {
                this.inputs[i].ImageKeyUrl = await this.imageUpload(this.inputs[i].ImageKeyUrl);
              }

              if(this.inputs[i].ImageValueUrl != null) {
                this.inputs[i].ImageValueUrl = await this.imageUpload(this.inputs[i].ImageValueUrl);
              }
            }

            this.questionForm.MatchingChoices = this.inputs;
          }

          this.$goc.request.post(
            this.$goc.ENUMS.API.Question,
            this.questionForm,
            () => {
              this.$refs.form.reset();
              this.$refs.form.resetValidation();
              this.questionForm.Text = "";
              this.questionForm.Choices = [];
              this.$goc.notify.success({
                title: this.$t('_common.Success'),
                message: this.$t('_add_questions.Question_successfully_added'),
              });
              this.inputs.length = 1;
            }
          );
          this.$goc.console.log(this.questionForm);
        } else {
          this.$goc.notify.warning({
            title: this.$t('_common.Warning'),
            message: this.$t('_add_questions.You_must_select_only_one_correct_answer')
          });
        }
      } else this.$goc.console.log("Invalid");
    },
    addChoiceInput() {
      let lastChoice = this.inputs[this.inputs.length - 1];
      if (lastChoice != undefined && (lastChoice.value === null || lastChoice.value === "")) {
        this.$goc.notify.warning({
          title: this.$t('_common.Warning'),
          message: this.$t('_add_questions.You_must_fill_the_question')
        });
        return; // terminate
      }
      this.inputs.push({
        Text: "",
        Value: "",
        Correct: false,
      });
    },
    // checkTxtNull(input) {
    //    if (input.value.length > 0) {
    //      var minus = this.$refs["minus-" + input.id];
    //      this.$goc.console.log(minus);
    //    }
    // },
    imageUpload(ImageUrl){
      let formData = new FormData();
      formData.append("file", ImageUrl);
      return new Promise((resolve) => {
        this.$goc.request.postMultipart(
          this.$enums.API.Images.Single,
          formData,
          response => {
            resolve(response.result.url);
          }
        );
      })
    },
    deleteInputs() {
      var item = this.inputs[0];
      this.inputs[0].value = "";
      this.inputs = [];
      this.inputs.push(item);
    },
    validateForm() {
      return new Promise((resolve, reject) => {
        var selectedNodes = this.selected;
        return selectedNodes
          ? resolve(true)
          : reject(this.$t('_add_questions.You_must_fill_the_question'));
      });
    },
    onSelectedTopic(item) {
      this.$goc.console.log(this.treeTopicDataModel.length);
      this.$goc.console.log(item);
    },
    deleteChoice(choice) {
      if (choice != undefined) {
        var pos = this.inputs.indexOf(choice);
        if (this.inputs.length > 1) {
          this.inputs.splice(pos, 1);
          //document.getElementById("choice" + pos).focus();
        }
      }
    }
  },
  components: {
    VTree,
    VSelectTree,
    Sortable
  },
  mounted: function() {
    this.getTopics();
    this.init();
    this.questionForm.Topics.push(this.topic);
    this.$goc.setModule("AddQuestion", this);
  }
};
</script>
<style>
.select-lms-area{
  width: 100%;
  align-items: baseline;
}
.choose-question{
  width: 60%;
}
.file-selector .v-input__control{
  min-width: 100px;
  max-width: 250px;
}
.ck.ck-editor {
  font-size: 15px;
}

.ck.ck-editor__editable {
  padding: 1rem 2rem 2rem;
}

.ck.ck-editor__editable > .ck-placeholder::before {
  color: #1976d2;
  font-family: Georgia;
  font-size: 24px;
}

.ck-editor__editable {
  max-height: 300px !important;
}
</style>
